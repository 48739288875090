import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Profile from './pages/Profile';
import Stats from './pages/Stats';
import Help from './pages/Help';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import Purchase from './pages/Purchase';
import PurchaseOnly from './pages/PurchaseOnly';
import CanvaPurchase from './pages/CanvaPurchase.tsx';
import CanvaBack from './pages/CanvaBack.tsx';
import PurchaseResponse from './pages/PurchaseResponse';
import MyMusic from './pages/MyMusic';
import AuthLayout from './layouts/AuthLayout'; 
import MainLayout from './layouts/MainLayout';
import EmptyLayout from './layouts/EmptyLayout';

import ProtectedRoute from './components/ProtectedRoute';
import GoogleRedirectHandler from './components/GoogleRedirectHandler';
import Logout from './components/Logout';
import CanvaRoute from './components/CanvaRoute.tsx';
import { CanvaAuthProvider } from './contexts/CanvaAuthContext2.tsx';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
         
		<Route element={<AuthLayout />}>
            <Route path="/canva-back" element={<CanvaBack />} />
          </Route>

          {/* CanvaRoute */}
          
          <Route element={<EmptyLayout />}>
			  <Route
				path="/canva/*"
				element={
				  <CanvaAuthProvider>
					<CanvaRoute>
					  <Routes>
						<Route path="upgrade" element={<CanvaPurchase />} />
						<Route path="manage" element={<CanvaPurchase />} />
					  </Routes>
					</CanvaRoute>
				  </CanvaAuthProvider>
				}
			  />
		</Route>

          
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
